body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
html, body { height: 100% }

body {
  background-color: #f9f9f9;
}

h1 {
  text-align: center;
}
._loading_overlay_wrapper--active {
  position: fixed !important;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.buttonHover {
  visibility: hidden;
  line-height: 0;
  padding: 0;
}

.buttonControl:hover .buttonHover {
  visibility: visible;
}
